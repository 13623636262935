<template>
  <v-row justify="space-around">
    <v-sheet
      color="primary lighten-2"
      elevation="12"
      rounded
      class="pa-6 white--text"
      max-width="800px"
    >
      <h2 class="h2 mb-4">Folsom Police Dept. Toy Drive - {{ activeEvent }}</h2>
      <h3 class="h3 mb-4">Sponsored by the Folsom Police Foundation</h3>
      <Banner v-if="banner" class="mb-3" v-model="bannerText" />
      <p>
        Help make Christmas magical for Folsom families in need this holiday
        season!
      </p>
      <p>
        Folsom CAPS (Citizens Assisting Public Safety), Folsom Police and Folsom
        Fire Department have worked with local schools to identify families in
        need in our community. Help make their Christmas special by selecting a
        wish from our Virtual Tree or list and deliver your donation of new and
        unwrapped toys, clothing, and gift cards to Folsom Police Department, 46
        Natoma Street or Folsom Fire Department Station #35, 535 Glenn Drive.
        <b
          >If you are donating gift cards or money, please hand it directly to a
          staff member at the location.</b
        >
        Drop-off hours can be found below:
      </p>
      <v-card class="mx-auto" elevation="10">
        <v-simple-table dense dark class="indigo lighten-1">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left"></th>
                <th class="text-left">
                  Folsom Police Department<br />46 Natoma St<br />
                </th>
                <th class="text-left">
                  Folsom Fire Department<br />535 Glenn Dr<br />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in hours" :key="item.name">
                <td>{{ item.day }}</td>
                <td>{{ item.policeHours }}</td>
                <td>{{ item.fireHours }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
      <p class="mt-5">
        Each ornament on the tree has an available Amazon Wish List that you
        will receive via an email when you select the ornament. When you
        purchase your gifts from the child’s Amazon Wish List, you can have it
        shipped directly to Folsom Police Department and we will ensure that it
        is delivered to your wish child. You can also purchase your items
        locally and bring them to the Folsom Police or Fire Department.
      </p>
      <p class="mt-5">
        <b>Shop early! Be aware of shipping dates and potential delays.</b>
        Please have your gifts delivered by December 6th, 2024 to ensure that
        they are properly and safely delivered to your wish child by Folsom
        Police and Fire Department personnel.
      </p>
      <p>Thank you and remember…. Bring the magic - Be an elf!</p>
      <p>
        Wishing you and your family a very festive, safe and happy holiday
        season!
      </p>
      <p>Select your wish below:</p>
      <v-row justify="space-around">
        <TreeCard
          v-if="
            selectionMethod === 'Tree and List' ||
            selectionMethod === 'Tree Only'
          "
        ></TreeCard>
        <SheetCard
          v-if="
            selectionMethod === 'Tree and List' ||
            selectionMethod === 'List Only'
          "
        ></SheetCard>
      </v-row>
    </v-sheet>
  </v-row>
</template>

<script>
import { get } from "vuex-pathify"
import { routeNames } from "./../router/config"
import TreeCard from "@/components/nav/TreeCard"
import SheetCard from "@/components/nav/SheetCard"
import { analytics, EventName } from "./../services/firebase"
import Banner from "./Banner/Viewer.vue"

export default {
  name: "HomeActiveContent",
  components: {
    TreeCard,
    SheetCard,
    Banner
  },
  props: {
    banner: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    bannerText: {
      type: String,
      default: function () {
        return ""
      }
    },
    selectionMethod: {
      type: String,
      default: function () {
        return "Tree Only"
      }
    }
  },
  data() {
    return {
      hours: [
        {
          day: "Monday",
          policeHours: "8 a.m. - 4 p.m.",
          fireHours: "8 a.m. - 4:30 p.m."
        },
        {
          day: "Tuesday",
          policeHours: "8 a.m. - 4 p.m.",
          fireHours: "8 a.m. - 4:30 p.m."
        },
        {
          day: "Wednesday",
          policeHours: "8 a.m. - 4 p.m.",
          fireHours: "8 a.m. - 4:30 p.m."
        },
        {
          day: "Thursday",
          policeHours: "8 a.m. - 4 p.m.",
          fireHours: "8 a.m. - 4:30 p.m."
        },
        {
          day: "Friday",
          policeHours: "8 a.m. - 4 p.m.",
          fireHours: "8 a.m. - 12 p.m."
        }
      ]
    }
  },
  computed: {
    treeLink() {
      return { name: routeNames.tree }
    },
    activeEvent: get("appSettings/activeEvent")
  },
  methods: {
    trackRedirect() {
      analytics.logEvent(EventName.SELECT_PROMOTION, {
        promotion_ID: "fpf",
        promotion_name: "FPF Donation",
        location_id: "HomeActiveContent",
        items: [{ item_id: "FPF" }]
      })
      return false
    }
  }
}
</script>
